import Recoil, { atom, selector } from "recoil";
import axios from '../utils/axios';

export const currentJobTypeID = atom({
    key: "currentJobTypeID",
    default: 0
});

export const currentJobTypeSelector = selector({
    key: "currentJobTypeSelector",
    get: async ({ get }) => {
        const jotID = get(currentJobTypeID);
        if (jotID) {
            try {
                const response = await axios.get(`/api/job-types/${jotID}`);
                const { data } = await response.data;
                return data;
            } catch (error) {
                console.log(error);
            }
        }
    },
    cachePolicy_UNSTABLE: {
        // Only store the most recent set of dependencies and their values
        eviction: 'most-recent',
    },
});

export const jobTypeTrigger = atom({
    key: "jobTypeTrigger",
    default: 0
});

export const jobTypesFilterParams = atom({
    key: 'jobTypesFilterParams',
    default: {
        limit: 100,
        dataFilter: { jotActive: { filter: true } },
        dataSort: [],
    }
})

export const jobTypesSelector = selector({
    key: "jobTypesSelector",
    get: async ({ get }) => {
        get(jobTypeTrigger);
        try {
            const strParams = JSON.stringify(get(jobTypesFilterParams));
            const response = await axios.get(`/api/job-types?params=${strParams}`);
            const { data } = await response.data;
            return data;
        } catch (error) {
            console.log(error);
        }
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(jobTypeTrigger, v => v + 1);
        }
    }
});
