import Dashboard from "views/Dashboard.js";
import Users from "views/users";
import UsersDetail from "views/users/Detail";
import JobTypes from "views/jobTypes";
import Locations from "views/locations";
import JobCardForm from "views/jobCards/JobCardForm";
import JobCards from "views/jobCards";
import JobDetail from "views/jobCards/JobDetail";
import Login from "views/pages/Login";

// roles
// 1: 'Admin', 2: 'Leader', 3: 'Supervisor'
// 4: 'Crew', 5: 'Tenant', 6: 'Building'

const routes = [
  {
    path: "/login",
    component: Login,
    hide: true,
    layout: "/auth",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/users/:usrID",
    component: UsersDetail,
    layout: "/admin",
    hide: true,
  },
  {
    path: "/job/:jobID",
    component: JobDetail,
    layout: "/admin",
    hide: true,
  },
  {
    path: "/job/:jobID/edit",
    component: JobCardForm,
    layout: "/admin",
    hide: true,
  },
  {
    collapse: true,
    name: "Job Cards",
    icon: "fa fa-calendar",
    state: "jobCarsCollapse",
    views: [
      {
        path: "/create-job-card",
        name: "Create",
        icon: "fa fa-plus",
        roles: [1],
        // roles: ['Admin', 'Leader', 'Supervisor', 'Crew', 'Tenant', 'Building'],
        component: JobCardForm,
        layout: "/admin",
      },
      {
        path: "/job-cards",
        name: "List",
        icon: "fa fa-list",
        component: JobCards,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Master Data",
    icon: "fa fa-database",
    state: "masterDataCollapse",
    views: [
      {
        path: "/users",
        name: "Users",
        icon: "fa fa-users",
        component: Users,
        layout: "/admin",
        roles: [1],
      },
      {
        path: "/job-types",
        name: "Job Types",
        icon: "fa fa-user-md",
        component: JobTypes,
        layout: "/admin",
      },
      {
        path: "/locations",
        name: "Locations",
        icon: "fa fa-map",
        component: Locations,
        layout: "/admin",
      },
    ],
  },
];

export default routes;
