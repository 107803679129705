import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-loading-skeleton/dist/skeleton.css'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <RecoilRoot>
        <Suspense fallback="loading...">
          <Redirect exact from="/" to="/auth/login" />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        </Suspense>
      </RecoilRoot>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
