import React from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from "react-router";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import axios from '../../utils/axios';
import asyncLocalStorage from "utils/asyncLocalStorage";
import { useSetRecoilState } from "recoil";
import { authState } from "recoil/Auth";

function Login() {
  const history = useHistory();
  const setAuthState = useSetRecoilState(authState)
  const formik = useFormik({
    initialValues: {
      usrName: '',
      usrPswd: '',
    },
    validationSchema: yup.object({
      usrName: yup
        .string('Enter your username')
        .required('Username is required'),
      usrPswd: yup
        .string('Enter your password')
        .required('Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/api/auth/login', values);
        const { data } = await response.data;
        await asyncLocalStorage.setItem('token', data.token);
        setAuthState(true);
        history.push("/admin/dashboard");
      } catch(error) {
        const { response } = error;
        if (response && response.status === 400) {
          alert(error.response.data.message);
        } else {
          console.log(error);
          alert('an error in server...');
        }
      }
    },
  });

  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });
  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="" onSubmit={formik.handleSubmit}>
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup className={formik.touched.username && Boolean(formik.errors.username) ? "has-danger mb-0" : ''}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Username"
                      name="usrName"
                      id="usrName"
                      value={formik.values.usrName}
                      onChange={formik.handleChange}
                    />
                  </InputGroup>
                  {formik.touched.usrName && Boolean(formik.errors.usrName) &&<label className="mt-0 mb-3 text-danger">{formik.errors.usrName}</label>}
                  <InputGroup className={formik.touched.password && Boolean(formik.errors.password) ? "mb-0 has-danger" : ''}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      name="usrPswd"
                      id="usrPswd"
                      value={formik.values.usrPswd}
                      onChange={formik.handleChange}
                      autoComplete="off"
                    />
                  </InputGroup>
                  {formik.touched.usrPswd && Boolean(formik.errors.usrPswd) &&<label className="mt-0 mb-3 text-danger">{formik.errors.password}</label>}
                  <br />
                  <FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input defaultChecked defaultValue="" type="checkbox" />
                        <span className="form-check-sign" />
                        Remember?
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    type="submit"
                    className="btn-round mb-3"
                    color="warning"
                    disabled={ (!(formik.isValid && formik.dirty) || formik.isSubmitting) }
                  >
                    Sign In
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${
            require("assets/img/bg/fabio-mangione.jpg").default
          })`,
        }}
      />
    </div>
  );
}

export default Login;
