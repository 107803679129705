import React from "react";
import { Card, CardBody, FormGroup, Input, Label } from "reactstrap";

const JobChecklists = (props) => {
  const { checkLists } = props;
  return (
    <Card style={{ backgroundColor: "#fff" }}>
      <CardBody>
        <span style={{ color: "#929292", fontSize: "10px" }}>Checklist</span>
        <hr style={{ margin: "0px", marginBottom: "8px" }} />
        {checkLists.map((check) => (
          <FormGroup key={check.jchID} check>
            <Label check>
              <Input
                type="checkbox"
                disabled
                checked={check.jchIsChecked ? "checked" : ""}
              />
              <span className="form-check-sign" />
              {check.jchDescription}
            </Label>
          </FormGroup>
        ))}
      </CardBody>
    </Card>
  );
};

export default JobChecklists;
