import Recoil, { atom, selector } from "recoil";
import axios from '../utils/axios';

export const currentLocationID = atom({
    key: "currentLocationID",
    default: 0
});

export const currentLocationSelector = selector({
    key: "currentLocationSelector",
    get: async ({ get }) => {
        const locID = get(currentLocationID);
        if (locID) {
            try {
                const response = await axios.get(`/api/locations/${locID}`);
                const { data } = await response.data;
                return data;
            } catch (error) {
                console.log(error);
            }
        }
    },
    cachePolicy_UNSTABLE: {
        // Only store the most recent set of dependencies and their values
        eviction: 'most-recent',
    },
});

export const locationTrigger = atom({
    key: "locationTrigger",
    default: 0
});

export const locationsFilterParams = atom({
    key: 'locationsFilterParams',
    default: {
        limit: 100,
        dataFilter: { locActive: { filter: true } },
        dataSort: [],
    }
})

export const locationsSelector = selector({
    key: "locationsSelector",
    get: async ({ get }) => {
        get(locationTrigger);
        try {
            const strParams = JSON.stringify(get(locationsFilterParams));
            const response = await axios.get(`/api/locations?params=${strParams}`);
            const { data } = await response.data;
            return data;
        } catch (error) {
            console.log(error);
        }
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(locationTrigger, v => v + 1);
        }
    }
});
