import React from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useResetRecoilState } from "recoil";
import { Button, Input, Label } from "reactstrap";
import axios from "../../utils/axios";
import UploadImage from "assets/img/upload-image.png";
import { useParams } from "react-router";
import { currentJobSelector } from "recoil/Job";

const JobAddComment = (props) => {
  const { jobID } = useParams();
  const resetJob = useResetRecoilState(currentJobSelector);
  const validationSchema = yup.object({
    jcoDescription: yup.string().required("comment required"),
  });

  const initialValues = {
    jcoDescription: "",
    jcoImage: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const data = {
        jcoDescription: values.jcoDescription,
        jcoImage: values.jcoImage,
      };
      console.log(data.jcoImage)

      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }

      try {
        const response = await axios.post(`/api/jobs/${jobID}/comments`, formData);
        const { data } = response.data;
        resetJob();
        formik.resetForm();
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.log(error);
        const response = error.error.response;
        if (response.status !== 500) {
          const { data } = response.data;
          data.map((err) => formik.setFieldError(err.param, err.msg));
        }
      }
    },
  });

  return (
    <li className="timeline-inverted">
      <div
        className="timeline-badge"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        +
      </div>
      <div className="timeline-panel">
        <form onSubmit={formik.handleSubmit}>
          <div className="timeline-body">
            <div style={{ display: "flex" }}>
              <div>
                <Input
                  type="file"
                  id="jcoImage"
                  name="jcoImage"
                  onChange={(value) =>
                    formik.setFieldValue(
                      "jcoImage",
                      value.currentTarget.files[0]
                    )
                  }
                  hidden
                />
                <Label
                  for="jcoImage"
                  style={{ cursor: "pointer", width: "70px", height: "70px" }}
                >
                  <img
                    src={
                      formik.values.jcoImage
                        ? URL.createObjectURL(formik.values.jcoImage)
                        : UploadImage
                    }
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                </Label>
              </div>
              <div style={{ flexGrow: "1", marginLeft: "10px" }}>
                <Input
                  name="jcoDescription"
                  style={{ padding: "5px", lineHeight: "20px", height: "100%" }}
                  type="textarea"
                  value={formik.values.jcoDescription}
                  onChange={formik.handleChange}
                />
                {formik.touched.jcoDescription &&
                  Boolean(formik.errors.jcoDescription) && (
                    <label className="mt-0 mb-3 text-danger">
                      {formik.errors.jcoDescription}
                    </label>
                  )}
              </div>
            </div>
            <div>
              <Button
                style={{ backgroundColor: "#929292", float: "right" }}
                size="sm"
              >
                Add comment
              </Button>
            </div>
          </div>
        </form>
      </div>
    </li>
  );
};

export default JobAddComment;
