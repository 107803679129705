import React from "react";
import { Card, CardBody } from "reactstrap";

const JobCrews = (props) => {
  const { crews } = props;
  return (
    <Card style={{ backgroundColor: "#fff" }}>
      <CardBody>
        <span style={{ color: "#929292", fontSize: "10px" }}>Crews</span>
        <hr style={{ margin: "0px", marginBottom: "8px" }} />
        {crews.map((crew) => (
          <div
            key={crew.jcrID}
            style={{
              textAlign: "left",
              marginBottom: "10px",
            }}
          >
            <div style={{ width: "33.3%" }}>
              <span
                style={{
                  backgroundColor: crew.user.usrColorCode,
                  display: "inline-block",
                  width: "23px",
                  height: "23px",
                  borderRadius: "100px",
                  textAlign: "center",
                  lineHeight: "23px",
                  marginRight: "5px",
                  fontSize: "8px",
                  fontWeight: "bold",
                  color: "#ffffff",
                  verticalAlign: "middle",
                }}
              >
                {crew.user.usrInitial}
              </span>
              {crew.user.usrFullName}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-5px",
                paddingLeft: "28px",
                fontSize: "10px"
              }}
            >
              <div style={{ width: "33.3%" }}>
                <span style={{ color: "#929292", fontSize: "9px" }}>Check in Loc:</span>{" "}
                {crew.location ? crew.location.locName : "-"}
              </div>
              <div style={{ width: "33.3%" }}>
                <span style={{ color: "#929292", fontSize: "9px" }}>Check in:</span>{" "}
                {crew.jcrcheckIn || "-"}
              </div>
              <div style={{ width: "33.3%" }}>
                <span style={{ color: "#929292", fontSize: "9px" }}>Check out:</span>{" "}
                {crew.jcrcheckOut || "-"}
              </div>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default JobCrews;
