import React from 'react'
import Table from './table'

const JobCards = () => {
  return (
    <div className="content">
      <Table />
    </div>
  )
}

export default JobCards
