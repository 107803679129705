import React from "react";

import {
  NavbarBrand,
  Navbar,
  Container,
} from "reactstrap";

const AuthNavbar = () => (
  <Navbar className="navbar-absolute fixed-top">
    <Container className="justify-content-center">
      <div className="navbar-wrapper">
        <NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
          Building Management System
        </NavbarBrand>
      </div>
    </Container>
  </Navbar>
);

export default AuthNavbar;
