import { atom, selector } from 'recoil';
import axios from '../utils/axios';

export const authState = atom({
  key: "authState",
  default: 0
});

export const authUserState = selector({
  key: 'authUserState',
  get: async ({ get }) => {
      get(authState);
      try {
        const response = await axios.get('/api/me');
        const { data } = response.data;
        return data;
      } catch (error) {
        console.log(error)
      }
  }
})