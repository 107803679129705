import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import JobComments from "components/Jobs/JobComments";
import axios from "../../utils/axios";
import JobCrews from "components/Jobs/JobCrews";
import JobChecklists from "components/Jobs/JobChecklists";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentJobSelector } from "recoil/Job";
import { currentJobID } from "recoil/Job";

const JobDetail = () => {
  const { jobID } = useParams();
  const job = useRecoilValue(currentJobSelector);
  const setCurrentJobID = useSetRecoilState(currentJobID);

  useEffect(() => {
    setCurrentJobID(jobID);

    return () => setCurrentJobID(0);
  }, [jobID]);

  return (
    <div className="content">
      {!job ? (
        "loading ... "
      ) : (
        <Row style={{ justifyContent: "space-between" }}>
          <Col md="6">
            <Card style={{ backgroundColor: "#fff" }}>
              <CardBody>
                <Row>
                  <Col sm="4" style={{ color: "#929292" }}>
                    Job ID
                  </Col>
                  <Col sm="8" style={{ textAlign: "right" }}>
                    {job.jobNoForm}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm="4" style={{ color: "#929292" }}>
                    Schedules
                  </Col>
                  <Col sm="8" style={{ textAlign: "right" }}>
                    {job.jobPlanningStartDate} - {job.jobPlanningEndDate}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm="4" style={{ color: "#929292" }}>
                    Executions
                  </Col>
                  <Col sm="8" style={{ textAlign: "right" }}>
                    {job.jobStartDate || (
                      <small style={{ color: "#929292", fontSize: "9px" }}>
                        No Data
                      </small>
                    )}
                    {" - "}
                    {job.jobEndDate || (
                      <small style={{ color: "#929292", fontSize: "9px" }}>
                        No Data
                      </small>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: "5px" }}>
                  <Col sm="4" style={{ color: "#929292" }}>
                    Location
                  </Col>
                  <Col sm="8" style={{ textAlign: "right" }}>
                    {job.locName}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <JobCrews crews={job.crews} />
            <Card style={{ backgroundColor: "#fff" }}>
              <CardBody>
                <span style={{ color: "#929292", fontSize: "10px" }}>
                  Notes
                </span>
                <hr style={{ margin: "0px", marginBottom: "8px" }} />
                <p>{job.jobDescription}</p>
                {job.images.map((image) => (
                  <a key={image.jimID} href={image.jimPath} target="_blank">
                    <img
                      src={image.jimPath}
                      style={{
                        width: "55px",
                        height: "55px",
                        objectFit: "cover",
                        borderRadius: "2px",
                        marginRight: 5,
                      }}
                    />
                  </a>
                ))}
              </CardBody>
            </Card>
            <JobChecklists checkLists={job.checkLists} />
          </Col>
          <Col md="6" style={{ height: "100%" }}>
            <JobComments comments={job.comments} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default JobDetail;
