import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import ReactDatetime from "react-datetime";
import Select from "react-select";

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Button,
  Card,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import axios from "../../utils/axios";
import UploadImage from "assets/img/upload-image.png";
import { useHistory, useParams } from "react-router";
import { currentJobSelector } from "recoil/Job";
import { currentJobID } from "recoil/Job";
import moment from "moment";
import { jobsSelector } from "recoil/Job";
import JobSelectLocationModal from "components/Jobs/JobSelectLocationModal";

function JobCardForm() {
  const { jobID } = useParams();
  const history = useHistory();
  const resetJobs = useResetRecoilState(jobsSelector);
  const selectedJob = useRecoilValue(currentJobSelector);
  const setCurrentJobID = useSetRecoilState(currentJobID);
  const [crews, setCrews] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobImages, setJobImages] = useState([]);
  const [deletePicIDs, setDeletePicIDs] = useState([]);
  const isEditMode = jobID;

  const validationSchema = yup.object({
    jobPlanningStartDate: yup.date().required("Start date is required"),
    jobPlanningEndDate: yup.date().required("End date is required"),
  });

  const initialValues = {
    jobjotID: "",
    jobCekInt: "",
    joblocID: "",
    jobPlanningStartDate: "",
    jobPlanningEndDate: "",
    jobDescription: "",
    jobOption: {
      value: "R",
      label: "Routine",
    },
    usrIDs: [],
    jobImages: [],
    checkLists: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      const data = {
        jobjotID: values.jobjotID.value,
        jobCekInt: values.jobCekInt,
        jobPlanningStartDate:
          values.jobPlanningStartDate.format("YYYY-MM-DD HH:mm"),
        jobPlanningEndDate:
          values.jobPlanningEndDate.format("YYYY-MM-DD HH:mm"),
        joblocID: values.joblocID,
        jobOption: values.jobOption.value,
        jobDescription: values.jobDescription,
        checkListTexts: values.checkLists,
        deleteJobImages: deletePicIDs,
        usrIDs: values.usrIDs.map((crew) => crew.value),
        jobImages: values.jobImages.map((picture) => picture),
      };
      for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
          value.map((val) => formData.append(`${key}[]`, val));
        } else {
          formData.append(key, value);
        }
      }

      try {
        let message = "";
        let resjobID = "";
        if (isEditMode) {
          const res = await axios.put(`/api/jobs/${jobID}`, formData);
          message = res.data.message;
        } else {
          const res = await axios.post("/api/jobs", formData);
          message = res.data.messsage;
          resjobID = res.data.data.jobID;
        }
        setJobImages([]);
        resetJobs();
        formik.resetForm();
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: message,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => history.push(`/admin/job/${jobID || resjobID}`));
      } catch (error) {
        console.log(error);
        const response = error.response;
        if (response.status !== 500) {
          const { data } = response.data;
          console.log(data);
          data.map((err) => {
            if (err.noField) {
              alert(err.msg);
            } else {
              formik.setFieldError(err.param, err.msg);
            }
          });
        }
      }
    },
  });

  const removeJobCheck = (index) => {
    const currentChecklist = formik.values.checkLists;
    currentChecklist.splice(index, 1);
    formik.setFieldValue("checkLists", currentChecklist);
  };

  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        const response = await axios.get("/api/job-types/active");
        const { data } = await response.data;
        const jobTypesMap = data.map((obj) => {
          return { value: obj.jotID, label: obj.jotName };
        });
        setJobTypes(jobTypesMap);
      } catch (error) {
        const { data } = error.error.response;
        console.log(data);
        alert("Cannot fetch job types.");
      }
    };

    const fetchCrews = async () => {
      try {
        const filter = {
          dataFilter: {
            usrActive: { filter: true, filterType: "text", type: "contains" },
            usrRole: { filter: 4, filterType: "text", type: "contains" },
          },
        };
        const strFilter = JSON.stringify(filter);
        const response = await axios.get(`/api/users?params=${strFilter}`);
        const { data } = await response.data;
        const crewsData = data.map((obj) => {
          return { value: obj.usrID, label: obj.usrFullName };
        });

        setCrews(crewsData);
      } catch (error) {
        console.log(error);
        alert("Cannot fetch data crews");
      }
    };

    fetchCrews();
    fetchJobTypes();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setCurrentJobID(jobID);
      if (selectedJob) {
        const fields = ["jobDescription", "jobCekInt"];
        fields.map((field) =>
          formik.setFieldValue(field, selectedJob[field], false)
        );

        formik.setFieldValue(
          "jobPlanningStartDate",
          moment(selectedJob.jobPlanningStartDate),
          false
        );
        formik.setFieldValue(
          "jobPlanningEndDate",
          moment(selectedJob.jobPlanningEndDate),
          false
        );
        formik.setFieldValue(
          "usrIDs",
          selectedJob.crews.map((crew) => ({
            value: crew.jcrusrID,
            label: crew.user.usrFullName,
          }))
        );
        formik.setFieldValue(
          "checkLists",
          selectedJob.checkLists.map((checkList) => checkList.jchDescription)
        );
        formik.setFieldValue("joblocID", selectedJob.joblocID);
        formik.setFieldValue("jobjotID", {
          value: selectedJob.jobjotID,
          label: selectedJob.jotName,
        });
        formik.setFieldValue("jobOption", {
          value: selectedJob.jobOption,
          label: "Routine",
        });
        setJobImages(selectedJob.images);
      }
    }
    return () => setCurrentJobID(0);
  }, [selectedJob]);

  const handlePicture = (e) => {
    const file = e.currentTarget.files[0];
    const url = URL.createObjectURL(file);
    setJobImages([...jobImages, { jimPath: url }]);
    formik.setFieldValue("jobImages", [...formik.values.jobImages, file]);
  };

  const handleDeleteImage = (jobImage) => {
    const newJobImages = jobImages.filter(
      (image) => image.jimPath !== jobImage.jimPath
    );
    setJobImages(newJobImages);
    if (jobImage.jimID) setDeletePicIDs([...deletePicIDs, jobImage.jimID]);
  };

  return (
    <div className="content">
      <Card>
        <CardBody className="px-md-5">
          <Row>
            <Col md="12 mt-3">
              <Form
                onSubmit={formik.handleSubmit}
                className="form-horizontal"
                method="post"
              >
                <Row>
                  <Label sm="2">Schedule Type</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Choose schedule type"
                        isDisabled={true}
                        value={formik.values.jobOption}
                        onChange={(value) =>
                          formik.setFieldValue("jobOption", value)
                        }
                      />
                    </FormGroup>
                    {formik.touched.jobOption &&
                      Boolean(formik.errors.jobOption) && (
                        <label className="mt-0 mb-3 text-danger">
                          {formik.errors.jobOption}
                        </label>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Schedule</Label>
                  <Col md="5">
                    <FormGroup>
                      <ReactDatetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm"
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select start date",
                        }}
                        value={formik.values.jobPlanningStartDate}
                        onChange={(e) =>
                          formik.setFieldValue("jobPlanningStartDate", e)
                        }
                      />
                    </FormGroup>
                    {formik.touched.jobPlanningStartDate &&
                      Boolean(formik.errors.jobPlanningStartDate) && (
                        <label className="mt-0 mb-3 text-danger">
                          {formik.errors.jobPlanningStartDate}
                        </label>
                      )}
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <ReactDatetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat="HH:mm"
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select end date",
                        }}
                        value={formik.values.jobPlanningEndDate}
                        onChange={(e) =>
                          formik.setFieldValue("jobPlanningEndDate", e)
                        }
                      />
                    </FormGroup>
                    {formik.touched.jobPlanningEndDate &&
                      Boolean(formik.errors.jobPlanningEndDate) && (
                        <label className="mt-0 mb-3 text-danger">
                          {formik.errors.jobPlanningEndDate}
                        </label>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Job Type</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Choose job type"
                        name="jobjotID"
                        value={formik.values.jobjotID}
                        onChange={(value) =>
                          formik.setFieldValue("jobjotID", value)
                        }
                        options={jobTypes}
                      />
                    </FormGroup>
                    {formik.touched.jobjotID &&
                      Boolean(formik.errors.jobjotID) && (
                        <label className="mt-0 mb-3 text-danger">
                          {formik.errors.jobjotID}
                        </label>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Locations</Label>
                  <Col sm="10">
                    <FormGroup>
                      <JobSelectLocationModal
                        formik={formik}
                        selectedJob={selectedJob}
                      />
                    </FormGroup>
                    {formik.touched.joblocID &&
                      Boolean(formik.errors.joblocID) && (
                        <label className="mt-0 mb-3 text-danger">
                          {formik.errors.joblocID}
                        </label>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Crews</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        isMulti
                        name="crews"
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Choose crew for this job"
                        value={formik.values.usrIDs}
                        onChange={(value) =>
                          formik.setFieldValue("usrIDs", value)
                        }
                        options={crews}
                      />
                    </FormGroup>
                    {formik.touched.usrIDs && Boolean(formik.errors.usrIDs) && (
                      <label className="mt-0 mb-3 text-danger">
                        {formik.errors.usrIDs}
                      </label>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Description</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="jobDescription"
                        value={formik.values.jobDescription}
                        onChange={formik.handleChange}
                      />
                    </FormGroup>
                    {formik.touched.jobDescription &&
                      Boolean(formik.errors.jobDescription) && (
                        <label className="mt-0 mb-3 text-danger">
                          {formik.errors.jobDescription}
                        </label>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Images</Label>
                  <Col sm="10">
                    <Input
                      type="file"
                      id="jobImages"
                      onChange={(e) => handlePicture(e)}
                      hidden
                    />
                    <Row>
                      <Col sm="12">
                        {jobImages.map((image, index) => (
                          <div
                            key={index}
                            style={{
                              marginRight: "10px",
                              display: "inline-block",
                              position: "relative",
                            }}
                          >
                            <a
                              href={image.jimPath}
                              target="_blank"
                              style={{ cursor: "zoom-in" }}
                            >
                              <img
                                src={image.jimPath}
                                style={{
                                  objectFit: "cover",
                                  width: "100px",
                                  height: "100px",
                                }}
                              />
                            </a>
                            <i
                              className="fa fa-times p-1 bg-danger text-white rounded"
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteImage(image)}
                            ></i>
                          </div>
                        ))}

                        {jobImages.length < 4 && (
                          <Label
                            for="jobImages"
                            style={{
                              cursor: "pointer",
                              width: "100px",
                              height: "100px",
                            }}
                          >
                            <img src={UploadImage} width="100%" height="100%" />
                          </Label>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Label sm="2">Checklist</Label>
                  <Col sm="10">
                    {formik.values.checkLists.map((value, index) => (
                      <FormGroup key={index}>
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            style={{ cursor: "pointer" }}
                            onClick={() => removeJobCheck(index)}
                          >
                            <InputGroupText style={{ paddingRight: 9 }}>
                              <i className="fa fa-close text-danger" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input readOnly value={value} />
                        </InputGroup>
                      </FormGroup>
                    ))}
                    <FormGroup>
                      <Input
                        type="text"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            formik.setFieldValue("checkLists", [
                              ...formik.values.checkLists,
                              e.target.value,
                            ]);
                            e.target.value = "";
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Label sm="2" />
                  <Col sm="10" className="text-right">
                    <FormGroup>
                      <Button type="submit" color="primary">
                        {isEditMode ? "Save" : "Create job"}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default JobCardForm;
