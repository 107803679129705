import Recoil, { atom, selector } from "recoil";
import axios from '../utils/axios';

export const currentUserID = atom({
    key: "currentUserID",
    default: 0
});

export const currentUserSelector = selector({
    key: "currentUserSelector",
    get: async ({ get }) => {
        const userID = get(currentUserID);
        if (userID) {
            try {
                const response = await axios.get(`/api/users/${userID}`);
                const { data } = await response.data;
                return data;
            } catch (error) {
                console.log(error);
            }
        }
    },
    cachePolicy_UNSTABLE: {
        // Only store the most recent set of dependencies and their values
        eviction: 'most-recent',
    },
});

export const usersTrigger = atom({
    key: "usersTrigger",
    default: 0
});

export const usersFilterParams = atom({
    key: 'usersFilterParams',
    default: {
        limit: 100,
        dataFilter: { usrActive: { filter: true } },
        dataSort: [],
    }
})

export const usersSelector = selector({
    key: "usersSelector",
    get: async ({ get }) => {
        get(usersTrigger);
        try {
            const strParams = JSON.stringify(get(usersFilterParams));
            const response = await axios.get(`/api/users?params=${strParams}`);
            const { data } = await response.data;
            return data;
        } catch (error) {
            console.log(error.response);
        }
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(usersTrigger, v => v + 1);
        }
    }
});
