import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { AgGridReact } from "ag-grid-react";
import { Progress } from "reactstrap";

import axios from "../../utils/axios";
import { jobsFilterParams, jobsSelector } from "../../recoil/Job";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "assets/css/ag-grid.css";
import { authUserState } from "recoil/Auth";

const Table = () => {
  const history = useHistory();
  const [gridApi, setGridApi] = useState(null);
  const [queryParams, setQueryParams] = useRecoilState(jobsFilterParams);
  const authUser = useRecoilValue(authUserState);
  const rowData = useRecoilValue(jobsSelector);
  const resetJobs = useResetRecoilState(jobsSelector);

  // grid options
  const gridOptions = {
    onFilterChanged: (params) => {
      const dataFilter = params.api.getFilterModel();
      setQueryParams({ ...queryParams, dataFilter });
    },

    onSortChanged: (params) => {
      const dataSort = params.api.getSortModel();
      setQueryParams({ ...queryParams, dataSort });
    },

    suppressCellSelection: true,
  };

  // saat grid ready, set data awal
  const onGridReady = (params) => {
    params.columnApi.autoSizeColumns(
      ["locName", "jobNoForm", "jotName", "actionButton", "jobVoid"],
      false
    );
    setGridApi(params.api);
  };

  // set data filter
  const setDataFilter = () => {
    const { dataFilter } = queryParams;

    Object.entries(dataFilter).forEach((e) => {
      const filterInstance = gridApi.getFilterInstance(e[0]);
      const model = {
        type: "contains",
        filter: dataFilter[e[0]].filter,
      };
      filterInstance.setModel(model);
    });
  };

  const actionButton = (cell) => {
    const { data } = cell;

    const handleEdit = () => {
      history.push(`/admin/job/${data.jobID}/edit`);
    };

    const handleView = () => {
      history.push(`/admin/job/${cell.data.jobID}`);
    };

    const handleLockUnlock = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, void it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.patch(`/api/jobs/${data.jobID}/void`);
          Swal.fire("Success!", res.data.message, "success");
          resetJobs();
        }
      });
    };

    return (
      <>
        {!data.jobVoid && (
          <div>
            <i onClick={handleView} role="button" className="fa fa-eye"></i>
            {authUser.usrRole === 1 && (
              <>
                <i
                  onClick={handleEdit}
                  role="button"
                  className="fa fa-edit ml-2"
                ></i>
                <i
                  onClick={handleLockUnlock}
                  role="button"
                  className={`fa fa-trash ml-2`}
                ></i>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const progressCell = (cell) => {
    const { data } = cell;

    return (
      <>
        <div style={{ display: "flex", flexDirection: "" }}>
          <div style={{ flexGrow: 1 }}>
            <Progress
              value={Math.floor(
                (data.totalChecked / data.totalChecklist) * 100
              )}
              style={{ marginRight: "10px", marginTop: "17px" }}
            />
          </div>
          <div>{`${data.totalChecked}/${data.totalChecklist}`}</div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (gridApi) {
      setDataFilter();
    }
  }, [gridApi]);

  const jobVoidFilter = forwardRef((_props, ref) => {
    const inputRef = useRef(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          if (!parentModel) {
            inputRef.current.value = "";
          } else {
            inputRef.current.value = parentModel.filter + "";
          }
        },
      };
    });

    const onSelectOptionChanged = (input) => {
      _props.parentFilterInstance((instance) => {
        const gridApi = instance.textFilterParams.api;
        const filterInstance = gridApi.getFilterInstance("jobVoid");
        const model = {
          type: "contains",
          filter: input.target.value,
        };
        filterInstance.setModel(model);
        gridApi.onFilterChanged();
      });
    };

    return (
      <>
        <select
          className="select-box-floating-filter"
          ref={inputRef}
          onInput={onSelectOptionChanged}
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
          <option value="">All</option>
        </select>
      </>
    );
  });

  const jobStatusFilter = forwardRef((_props, ref) => {
    const inputRef = useRef(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          if (!parentModel) {
            inputRef.current.value = "";
          } else {
            inputRef.current.value = parentModel.filter + "";
          }
        },
      };
    });

    const onSelectOptionChanged = (input) => {
      _props.parentFilterInstance((instance) => {
        const gridApi = instance.textFilterParams.api;
        const filterInstance = gridApi.getFilterInstance("jobStatus");
        const model = {
          type: "contains",
          filter: input.target.value,
        };
        filterInstance.setModel(model);
        gridApi.onFilterChanged();
      });
    };

    return (
      <>
        <select
          className="select-box-floating-filter"
          ref={inputRef}
          onInput={onSelectOptionChanged}
        >
          <option value="10">Waiting for checking</option>
          <option value="20">Already checked</option>
          <option value="30">Waiting for approval</option>
          <option value="40">Waiting for assignment</option>
          <option value="50">Waiting for implementation</option>
          <option value="60">Crew working on</option>
          <option value="70">Wait for check supervisor</option>
          <option value="80">Done</option>
          <option value="">All</option>
        </select>
      </>
    );
  });

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    floatingFilter: true,
    suppressMenu: true,
    resizable: true,
    autoResizable: true,
    filterParams: { apply: false, newRowsAction: "keep", buttons: ["apply"] },
  };

  const columnDefs = [
    { headerName: "#JOB", field: "jobNoForm" },
    { headerName: "Job Type", field: "jotName" },
    {
      headerName: "Progress",
      field: "progress",
      cellRenderer: "progressCell",
    },
    { headerName: "Location", field: "locName" },
    {
      headerName: "Schedule Time",
      field: "scheduleTime",
      cellRenderer: (params) =>
        `${params.data.jobPlanningStartDate} - ${params.data.jobPlanningEndDate}`,
    },
    {
      headerName: "Execution Time",
      field: "executionTime",
      cellRenderer: (params) =>
        `${params.data.jobStartDate} - ${params.data.jobEndDate}`,
    },
    {
      headerName: "Void?",
      field: "jobVoid",
      floatingFilterComponent: "jobVoidFilter",
      cellRenderer: (params) => params.data.jobVoidLabel,
    },
    {
      headerName: "Status",
      field: "jobStatus",
      floatingFilterComponent: "jobStatusFilter",
      width: "250px",
      cellRenderer: (params) => params.data.jobStatusLabel,
    },
    {
      headerName: "Action",
      field: "actionButton",
      cellRenderer: "actionButton",
      filter: false,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 570 }}>
      {/* AG Grid */}
      <AgGridReact
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        rowData={rowData}
        frameworkComponents={{
          actionButton,
          jobVoidFilter,
          jobStatusFilter,
          progressCell,
        }}
        rowSelection="single"
        columnDefs={columnDefs}
        onCellDoubleClicked={(cell) =>
          history.push(`/admin/job/${cell.data.jobID}`)
        }
      />
    </div>
  );
};

export default Table;
