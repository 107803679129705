import React from "react";
import QRCode from "react-qr-code";
import { useRecoilValue } from "recoil";

import { Row, Col } from "reactstrap";
import { currentLocationSelector } from "recoil/Location";

function LocationQR() {
  const selectedLocation = useRecoilValue(currentLocationSelector);

  return !selectedLocation ? (
    "loading..."
  ) : (
    <>
      <Row id="divToPrint" style={{ padding: 20, display: "flex", justifyContent: "left", alignItems: "center" }}>
        <QRCode value={selectedLocation.locID} size="130" />
        <div style={{ marginLeft: "15px"}}>
          {selectedLocation.project ? (
            <p>Project: {selectedLocation.project.locName}</p>
          ) : (
            ""
          )}
          {selectedLocation.building ? (
            <p>Building: {selectedLocation.building.locName}</p>
          ) : (
            ""
          )}
          {selectedLocation.floor ? (
            <p>Floor: {selectedLocation.floor.locName}</p>
          ) : (
            ""
          )}
          <p style={{ marginBottom: 0 }}>
            {selectedLocation.locTypeLabel}: {selectedLocation.locName}
          </p>
        </div>
      </Row>
    </>
  );
}

export default LocationQR;
