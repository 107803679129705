import React, { Suspense, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button, Card, CardBody, Collapse } from 'reactstrap'
import { useResetRecoilState } from 'recoil';
import { usersSelector } from 'recoil/User';
import UserForm from './UserForm';

const Toolbars = (props) => {
    const { panelState, closePanel, openPanel, action } = props
    const [ disableRefreshButton, setDisableRefreshButton ] = useState(false);
    const resetUsers = useResetRecoilState(usersSelector);

    const refreshUsers = async () => {
        setDisableRefreshButton(true)
        await resetUsers();
        setDisableRefreshButton(false)
    }

    return (
        <>
            <div className="float-right">
                <Button 
                    className="btn-icon mt-0 mb-3"
                    title="Refresh table data"
                    color="dark"
                    disabled={disableRefreshButton}
                    onClick={refreshUsers}>
                    <i className="nc-icon nc-refresh-69" />
                </Button>
                <Button className="mt-0 mb-3" hidden={panelState} color="dark" onClick={() => openPanel('CREATE')}>Create new user</Button>
            </div>
            <div className="clearfix"></div>
            <Collapse isOpen={panelState}>
                <Card>
                    <CardBody className="px-md-5">
                        <Suspense fallback={<Skeleton height="50px" />}>
                            <UserForm closePanel={closePanel} action={action} />
                        </Suspense>
                    </CardBody>
                </Card>
            </Collapse>
        </>
    )
}

export default Toolbars
