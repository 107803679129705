import React, { Suspense, useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Skeleton from "react-loading-skeleton";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { locationsSelector } from "recoil/Location";
import LocationForm from "./LocationForm";
import LocationQR from "./LocationQR";
import { authUserState } from "recoil/Auth";

const Toolbars = (props) => {
  const { panelState, closePanel, openPanel, action } = props;
  const authUser = useRecoilValue(authUserState);
  const [disableRefreshButton, setDisableRefreshButton] = useState(false);
  const [isQRView, setIsQRView] = useState(false);
  const resetLocations = useResetRecoilState(locationsSelector);

  const refreshLocations = async () => {
    setDisableRefreshButton(true);
    await resetLocations();
    setDisableRefreshButton(false);
  };

  const generateQR = () => setIsQRView(true);

  useEffect(() => {
    if (isQRView) {
      const input = document.getElementById("divToPrint");

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "landscape",
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("download.pdf");
      });
      setIsQRView(false);
    }
  }, [isQRView]);

  return (
    <>
      <div className="float-right">
        <Button
          className="mt-0 mb-3"
          hidden={action !== "READ"}
          color="dark"
          onClick={() => generateQR()}
        >
          Generate QR
        </Button>
        <Button
          className="btn-icon mt-0 mb-3"
          title="Refresh table data"
          color="dark"
          disabled={disableRefreshButton}
          onClick={refreshLocations}
        >
          <i className="nc-icon nc-refresh-69" />
        </Button>
        {authUser.usrRole === 1 && (
          <Button
            className="mt-0 mb-3"
            hidden={panelState}
            color="dark"
            onClick={() => openPanel("CREATE")}
          >
            Create new location
          </Button>
        )}
      </div>
      <div className="clearfix"></div>
      <Collapse isOpen={panelState}>
        <Card>
          <CardBody className="px-md-5">
            <Suspense fallback={<Skeleton height="50px" />}>
              {isQRView ? (
                <LocationQR closePanel={closePanel} action={action} />
              ) : (
                <LocationForm closePanel={closePanel} action={action} />
              )}
            </Suspense>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export default Toolbars;
