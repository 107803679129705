import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import JobSelectLocationModalForm from "./JobSelectLocationModalForm";

const JobSelectLocationModal = (props) => {
  const { formik, selectedJob } = props;
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("");

  const handleCancel = () => {
    setOpen(false);
    setLabel("");
    formik.setFieldValue("joblocID", "");
  };

  const getLocationLabel = () => {
    const loc = selectedJob.locations;
    return `${loc.project ? loc.project.locName : ""} ${
      loc.building ? " / " + loc.building.locName : ""
    } ${loc.floor ? " / " + loc.floor.locName : ""}  / ${loc.locName}`;
  };

  useEffect(() => {
    setLabel(selectedJob ? getLocationLabel() : "");
  }, [selectedJob]);

  return (
    <div>
      <Input
        type="select"
        placeholder="Choose location"
        onClick={() => setOpen(true)}
      >
        <option>{label || "Choose location"}</option>
      </Input>
      <Modal isOpen={open} toggle={() => setOpen(open ? false : true)}>
        <ModalHeader>Choose location</ModalHeader>
        <ModalBody>
          <JobSelectLocationModalForm formik={formik} setLabel={setLabel} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setOpen(false)}>
            OK
          </Button>{" "}
          <Button onClick={handleCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default JobSelectLocationModal;
