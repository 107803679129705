import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { AgGridReact } from "ag-grid-react";

import axios from "../../utils/axios";
import {
  currentJobTypeID,
  jobTypesFilterParams,
  jobTypesSelector,
} from "../../recoil/JobType";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "assets/css/ag-grid.css";
import Swal from "sweetalert2";
import { authUserState } from "recoil/Auth";

const Table = (props) => {
  const { openPanel } = props;
  const authUser = useRecoilValue(authUserState);
  const [gridApi, setGridApi] = useState(null);
  const [queryParams, setQueryParams] = useRecoilState(jobTypesFilterParams);
  const rowData = useRecoilValue(jobTypesSelector);
  const setCurrentJobTypeID = useSetRecoilState(currentJobTypeID);
  const resetJobTypes = useResetRecoilState(jobTypesSelector);

  // grid options
  const gridOptions = {
    onFilterChanged: (params) => {
      const dataFilter = params.api.getFilterModel();
      setQueryParams({ ...queryParams, dataFilter });
    },

    onSortChanged: (params) => {
      const dataSort = params.api.getSortModel();
      setQueryParams({ ...queryParams, dataSort });
    },

    suppressCellSelection: true,
  };

  // saat grid ready, set data awal
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // set data filter
  const setDataFilter = () => {
    const { dataFilter } = queryParams;

    Object.entries(dataFilter).forEach((e) => {
      const filterInstance = gridApi.getFilterInstance(e[0]);
      const model = {
        type: "contains",
        filter: dataFilter[e[0]].filter,
      };
      filterInstance.setModel(model);
    });
  };

  const actionButton = (cell) => {
    const { data } = cell;

    const handleEdit = () => {
      setCurrentJobTypeID(data.jotID);
      openPanel("UPDATE");
    };

    const handleView = () => {
      setCurrentJobTypeID(data.jotID);
      openPanel("READ");
    };

    const handleLockUnlock = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: data.jotActive ? "Yes, lock it!" : "Yes, unlock it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await axios.patch(
            `/api/job-types/${data.jotID}/${data.jotActive ? "lock" : "unlock"}`
          );
          Swal.fire("Success!", res.data.message, "success");
          resetJobTypes();
        }
      });
    };

    return (
      <>
        <i onClick={handleView} role="button" className="fa fa-eye"></i>
        {authUser.usrRole === 1 && (
          <>
            <i
              onClick={handleEdit}
              role="button"
              className="fa fa-edit ml-2"
            ></i>
            <i
              onClick={handleLockUnlock}
              role="button"
              className={`fa ${data.jotActive ? "fa-lock" : "fa-unlock"} ml-2`}
            ></i>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (gridApi) {
      setDataFilter();
    }
  }, [gridApi]);

  const jotActiveFilter = forwardRef((_props, ref) => {
    const inputRef = useRef(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          if (!parentModel) {
            inputRef.current.value = "";
          } else {
            inputRef.current.value = parentModel.filter + "";
          }
        },
      };
    });

    const onSelectOptionChanged = (input) => {
      _props.parentFilterInstance((instance) => {
        const gridApi = instance.textFilterParams.api;
        const filterInstance = gridApi.getFilterInstance("jotActive");
        const model = {
          type: "contains",
          filter: input.target.value,
        };
        filterInstance.setModel(model);
        gridApi.onFilterChanged();
      });
    };

    return (
      <>
        <select
          className="select-box-floating-filter"
          ref={inputRef}
          onInput={onSelectOptionChanged}
        >
          <option value={true}>Active</option>
          <option value={false}>Not Active</option>
          <option value="">All</option>
        </select>
      </>
    );
  });

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    floatingFilter: true,
    suppressMenu: true,
    filterParams: { apply: false, newRowsAction: "keep", buttons: ["apply"] },
  };

  const columnDefs = [
    { headerName: "Job Name", field: "jotName" },
    {
      headerName: "Status",
      field: "jotActive",
      floatingFilterComponent: "jotActiveFilter",
      cellRenderer: (params) => params.data.jotActiveLabel,
    },
    { headerName: "Action", cellRenderer: "actionButton", filter: false },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 570 }}>
      {/* AG Grid */}
      <AgGridReact
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        rowData={rowData}
        frameworkComponents={{ actionButton, jotActiveFilter }}
        rowSelection="single"
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default Table;
