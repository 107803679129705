import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as yup from 'yup';
import { useFormik } from "formik";
import Swal from "sweetalert2";

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";

import Select from "react-select";
import axios from '../../../utils/axios';
import { currentLocationSelector } from "recoil/Location";
import { locationsSelector } from "recoil/Location";
import { currentLocationID } from "recoil/Location";

function BuildingForm(props) {
  const { closePanel, action } = props;
  const selectedLocation = useRecoilValue(currentLocationSelector);
  const isEditMode = selectedLocation ? true : false;
  const resetLocations = useResetRecoilState(locationsSelector);
  const setCurrentLocationID = useSetRecoilState(currentLocationID);
  const [projects, setProjects] = useState([]);

  const validationSchema = yup.object({
    locName: yup.string().required("Location Name is required"),
  })

  const initialValues = {
    locName: '',
    locType: 'BD',
    locLatitude: '',
    locLongitude: '',
    locDispensation: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        locName: values.locName,
        locType: values.locType,
        projectCode: values.projectCode.value,
        locLatitude: values.locLatitude,
        locLongitude: values.locLongitude,
        locDispensation: values.locDispensation,
      }

      try {
        let message = '';
        if (isEditMode) {
          const res = await axios.put(`/api/locations/${selectedLocation.locID}`, data);
          message = res.data.message;
        } else {
          const res = await axios.post('/api/locations', data);
          message = res.data.message;
        }
        resetLocations();
        handleCancel();
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        const response = error.error.response;
        if (response.status !== 500) {
          const { data } = response.data;
          data.map((err) => formik.setFieldError(err.param, err.msg));
        }
        if (response.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Oops a Server Error Occurred',
          })
        }
      }
    },
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('/api/locations/project');
        const { data } = await response.data;
        const projectsData = [];
        data.map((project) => {
          projectsData.push({ value: project.locCode, label: project.locName })
        })
        setProjects(projectsData);
        if (isEditMode) {
          const project = projectsData.find( ({ value }) => value === selectedLocation.locCode.substr(0, 3));
          formik.setFieldValue('projectCode', { value: project.value, label: project.label});
        }
      } catch (error) {
        console.log(error);
        alert('Cannot fetch data roles');
      }
    }
    fetchProjects();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      const fields = ['locName', 'locLatitude', 'locLongitude', 'locDispensation'];
      fields.map((field) => formik.setFieldValue(field, selectedLocation[field], false))
    }
  }, [selectedLocation])

  const handleCancel = () => {
    closePanel();
    formik.resetForm();
    setCurrentLocationID(0);
  }

  return (
    <>
      <Row>
        <Col md="12 mt-3">
          <Form onSubmit={formik.handleSubmit} className="form-horizontal" method="post">
            <Row>
              <Label sm="2">Project</Label>
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Choose project"
                    isDisabled={action !== 'CREATE'}
                    value={formik.values.projectCode}
                    onChange={(value) => formik.setFieldValue('projectCode', value)}
                    options={projects}
                  />
                </FormGroup>
                {formik.touched.locations && Boolean(formik.errors.locations) && <label className="mt-0 mb-3 text-danger">{formik.errors.locations}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2">Location Name</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="locName"
                    readOnly={action === 'READ'}
                    value={formik.values.locName}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.locName && Boolean(formik.errors.locName) && <label className="mt-0 mb-3 text-danger">{formik.errors.locName}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2">Location</Label>
              <Col sm="10">
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Input 
                        placeholder="Latitude"
                        type="text"
                        name="locLatitude"
                        readOnly={action === 'READ'}
                        value={formik.values.locLatitude}
                        onChange={formik.handleChange} />
                    </FormGroup>
                    {formik.touched.locLatitude && Boolean(formik.errors.locLatitude) && <label className="mt-0 mb-3 text-danger">{formik.errors.locLatitude}</label>}

                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Input 
                        placeholder="Longitude"
                        type="text"
                        name="locLongitude"
                        readOnly={action === 'READ'}
                        value={formik.values.locLongitude}
                        onChange={formik.handleChange} />
                    </FormGroup>
                    {formik.touched.locLongitude && Boolean(formik.errors.locLongitude) && <label className="mt-0 mb-3 text-danger">{formik.errors.locLongitude}</label>}
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Input 
                        placeholder="Dispensation (in meters)"
                        type="text"
                        name="locDispensation"
                        readOnly={action === 'READ'}
                        value={formik.values.locDispensation}
                        onChange={formik.handleChange} />
                    </FormGroup>
                    {formik.touched.locDispensation && Boolean(formik.errors.locDispensation) && <label className="mt-0 mb-3 text-danger">{formik.errors.locDispensation}</label>}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Label sm="2" />
              <Col sm="10" className="text-right">
                <FormGroup>
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    {action === 'READ' ? 'Close' : 'Cancel'}
                  </Button>
                  {action !== 'READ' &&
                    <Button
                      type="submit"
                      color="primary">
                      Save
                    </Button>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default BuildingForm;
