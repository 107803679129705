import React, { Suspense, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button, Card, CardBody, Collapse } from 'reactstrap'
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { authUserState } from 'recoil/Auth';
import { jobTypesSelector } from 'recoil/JobType';
import JobTypeForm from './JobTypeForm';

const Toolbars = (props) => {
    const { panelState, closePanel, openPanel, action } = props
    const [ disableRefreshButton, setDisableRefreshButton ] = useState(false);
    const authUser = useRecoilValue(authUserState);
    const resetJobTypes = useResetRecoilState(jobTypesSelector);

    const refreshJobTypes = async () => {
        setDisableRefreshButton(true)
        await resetJobTypes();
        setDisableRefreshButton(false)
    }

    return (
        <>
            <div className="float-right">
                <Button 
                    className="btn-icon mt-0 mb-3"
                    title="Refresh table data"
                    color="dark"
                    disabled={disableRefreshButton}
                    onClick={refreshJobTypes}>
                    <i className="nc-icon nc-refresh-69" />
                </Button>
                {authUser.usrRole === 1 && (
                    <Button className="mt-0 mb-3" hidden={panelState} color="dark" onClick={() => openPanel('CREATE')}>Create new job type</Button>
                )}
            </div>
            <div className="clearfix"></div>
            <Collapse isOpen={panelState}>
                <Card>
                    <CardBody className="px-md-5">
                        <Suspense fallback={<Skeleton height="50px" />}>
                            <JobTypeForm closePanel={closePanel} action={action} />
                        </Suspense>
                    </CardBody>
                </Card>
            </Collapse>
        </>
    )
}

export default Toolbars
