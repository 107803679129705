import Recoil, { atom, selector } from "recoil";
import axios from '../utils/axios';

export const jobTrigger = atom({
    key: "jobTrigger",
    default: 0
});

export const currentJobID = atom({
    key: "currentJobID",
    default: 0
});

export const currentJobSelector = selector({
    key: "currentJobSelector",
    get: async ({ get }) => {
        get(jobTrigger);
        const jobID = get(currentJobID);
        if (jobID) {
            try {
                const response = await axios.get(`/api/jobs/${jobID}`);
                const { data } = await response.data;
                return data;
            } catch (error) {
                console.log(error);
            }
        }
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(jobTrigger, v => v + 1);
        }
    },
    cachePolicy_UNSTABLE: {
        // Only store the most recent set of dependencies and their values
        eviction: 'most-recent',
    },
});

export const jobsFilterParams = atom({
    key: 'jobsFilterParams',
    default: {
        limit: 100,
        dataFilter: { jobVoid: { filter: false }, jobStatus: { filter: 50 } },
        dataSort: [],
    }
})

export const jobsSelector = selector({
    key: "jobsSelector",
    get: async ({ get }) => {
        get(jobTrigger);
        try {
            const strParams = JSON.stringify(get(jobsFilterParams));
            const response = await axios.get(`/api/jobs?params=${strParams}`);
            const { data } = await response.data;
            return data;
        } catch (error) {
            console.log(error);
        }
    },
    set: ({ set }, value) => {
        if (value instanceof Recoil.DefaultValue) {
            set(jobTrigger, v => v + 1);
        }
    }
});
