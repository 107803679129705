import React, { useEffect } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as yup from 'yup';
import { useFormik } from "formik";
import Swal from "sweetalert2";

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";

import axios from '../../utils/axios';
import { currentJobTypeSelector } from "recoil/JobType";
import { jobTypesSelector } from "recoil/JobType";
import { currentJobTypeID } from "recoil/JobType";

function JobTypeForm(props) {
  const { closePanel, action } = props;
  const selectedJobType = useRecoilValue(currentJobTypeSelector);
  const isEditMode = selectedJobType ? true : false;
  const resetJobTypes = useResetRecoilState(jobTypesSelector);
  const setCurrentJobTypeID = useSetRecoilState(currentJobTypeID);

  const validationSchema = yup.object({
    jotName: yup.string().required("Job Type Name is required"),
  })

  const initialValues = {
    jotName: '',
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        jotName: values.jotName,
      }

      try {
        let message = '';
        if (isEditMode) {
          const res = await axios.put(`/api/job-types/${selectedJobType.jotID}`, data);
          message = res.data.message;
        } else {
          const res = await axios.post('/api/job-types', data);
          message = res.data.message;
        }
        resetJobTypes();
        handleCancel();
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        const response = error.error.response;
        if (response.status !== 500) {
          const { data } = response.data;
          data.map((err) => formik.setFieldError(err.param, err.msg));
        }
      }
    },
  });

  useEffect(() => {
    if (isEditMode) {
      const fields = ['jotName'];
      fields.map((field) => formik.setFieldValue(field, selectedJobType[field], false))
    }
  }, [selectedJobType])

  const handleCancel = () => {
    closePanel();
    formik.resetForm();
    setCurrentJobTypeID(0);
  }

  return (
    <>
      <Row>
        <Col md="12 mt-3">
          <Form onSubmit={formik.handleSubmit} className="form-horizontal" method="post">
            <Row>
              <Label sm="2">Job Type Name</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="jotName"
                    readOnly={action === 'READ'}
                    value={formik.values.jotName}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.jotName && Boolean(formik.errors.jotName) && <label className="mt-0 mb-3 text-danger">{formik.errors.jotName}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2" />
              <Col sm="10" className="text-right">
                <FormGroup>
                  <Button
                      type="submit"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      {action === 'READ' ? 'Close' : 'Cancel'}
                  </Button>
                  {action !== 'READ' && 
                    <Button
                      type="submit"
                      color="primary">
                      Save
                    </Button>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default JobTypeForm;
