import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import ProjectForm from "./forms/ProjectForm";
import { currentLocationSelector } from "recoil/Location";
import BuildingForm from "./forms/BuildingForm";
import FloorForm from "./forms/FloorForm";
import RoomForm from "./forms/RoomForm";

const locationTypes = [
  { value: 'PR', label: 'Project' },
  { value: 'BD', label: 'Building' },
  { value: 'FL', label: 'Floor' },
  { value: 'RO', label: 'Room' },
]

function LocationForm(props) {
  const { action } = props;
  const selectedLocation = useRecoilValue(currentLocationSelector);
  const [locationType, setLocationType] = useState({ value: locationTypes[0].value, label: locationTypes[0].label });
  
  useEffect(() => {
    if (selectedLocation) { 
      setLocationType({ value: selectedLocation.locType, label: selectedLocation.locTypeLabel });
    }

    return () => setLocationType({ value: locationTypes[0].value, label: locationTypes[0].label });
  }, [selectedLocation, action])

  return (
    <>
      <Row>
        <Col md="12 mt-3">
          <Form className="form-horizontal" method="post">
            <Row>
              <Label sm="2">Location Type?</Label>
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Choose location type"
                    isDisabled={action !== 'CREATE'}
                    options={locationTypes}
                    value={locationType}
                    onChange={(e) => setLocationType(e)}
                  />
                </FormGroup>
              </Col>

            </Row>
          </Form>
          {
            {
              'PR': <ProjectForm {...props} />,
              'BD': <BuildingForm {...props} />,
              'FL': <FloorForm {...props} />,
              'RO': <RoomForm {...props} />,
            }[locationType.value]
          }
        </Col>
      </Row>
    </>
  );
}

export default LocationForm;
