import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as yup from 'yup';
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Select from "react-select";

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormText,
  Button,
} from "reactstrap";

import axios from '../../utils/axios';
import { usersSelector } from "recoil/User";
import { currentUserSelector } from "recoil/User";
import { currentUserID } from "recoil/User";

function UserForm(props) {
  const { closePanel, action } = props;
  const selectedUser = useRecoilValue(currentUserSelector);
  const isEditMode = selectedUser ? true : false;
  const [dataLocation, setDataLocation] = useState([]);
  const [dataRole, setDataRole] = useState([]);
  const resetUsers = useResetRecoilState(usersSelector);
  const setCurrentUserID = useSetRecoilState(currentUserID);

  const validationSchema = yup.object({
    usrName: yup.string().required("username is required"),
    usrFullName: yup.string().required("Fullname is required"),
    usrPswd: yup.string().concat(!isEditMode ? yup.string().required('Password is required') : null),
    passwordConfirmation: yup.string().oneOf([yup.ref('usrPswd'), null], 'Password confirmation must match'),
    // usrRole: yup.string().required("Role is required"),
  })

  const initialValues = {
    usrName: '',
    usrFullName: '',
    usrPswd: '',
    passwordConfirmation: '',
    usrRole: '',
    locationIDS: []
  };

  useEffect(() => {
    const fetchDataRole = async () => {
      try {
        const response = await axios.get('/api/users/role');
        const { data } = await response.data;
        const role = [];

        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            role.push({ value: key, label: data[key] });
          }
        }
        setDataRole(role);
      } catch (error) {
        const { data } = error.error.response;
        console.log(data);
        alert('Cannot fetch data roles');
      }
    }

    const fetchDataLocation = async () => {
      try {
        const response = await axios.get('/api/locations/active');
        const { data } = await response.data;
        const location = data.map((obj) => {
          return { value: obj.locID, label: obj.locName }
        });

        setDataLocation(location);
        // Storage.setItem('token', data.token);
      } catch (error) {
        const { data } = error.error.response;
        console.log(data);
        alert('Cannot fetch data locations');
      }
    }

    fetchDataLocation();
    fetchDataRole();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        usrName: values.usrName,
        usrFullName: values.usrFullName,
        usrPswd: values.usrPswd,
        passwordConfirmation: values.passwordConfirmation,
        usrRole: values.usrRole.value,
        locationIDS: values.locationIDS.map((location) => (location.value))
      }

      try {
        let message = '';
        if (isEditMode) {
          const res = await axios.put(`/api/users/${selectedUser.usrID}`, data);
          message = res.data.message;
        } else {
          const res = await axios.post('/api/users', data);
          message = res.data.message;
        }
        resetUsers();
        handleCancel();
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
        });
      } catch (error) {
        const response = error.error.response;
        if (response.status !== 500) {
          const { data } = response.data;
          data.map((err) => formik.setFieldError(err.param, err.msg));
        }
      }
    },
  });

  useEffect(() => {
    if (isEditMode) {
      const fields = ['usrName', 'usrFullName', 'usrRole', 'locations'];
      fields.map((field) => formik.setFieldValue(field, selectedUser[field], false))
      formik.setFieldValue('usrRole', { value: selectedUser.usrRole, label: selectedUser.usrRoleName });
      formik.setFieldValue('locationIDS', selectedUser.locations.map((location) => ({ value: location.locID, label: location.locName })));
    }
  }, [selectedUser])

  const handleCancel = () => {
    closePanel();
    formik.resetForm();
    setCurrentUserID(0);
  }

  return (
    <>
      <Row>
        <Col md="12 mt-3">
          <Form onSubmit={formik.handleSubmit} className="form-horizontal" method="post">
            <Row>
              <Label sm="2">Username</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="usrName"
                    readOnly={action === 'READ'}
                    value={formik.values.usrName}
                    onChange={formik.handleChange} />
                  <FormText color="default" tag="span">
                    Username is used for login.
                  </FormText>
                </FormGroup>
                {formik.touched.usrName && Boolean(formik.errors.usrName) && <label className="mt-0 mb-3 text-danger">{formik.errors.usrName}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2">Full name</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    type="text"
                    name="usrFullName"
                    readOnly={action === 'READ'}
                    value={formik.values.usrFullName}
                    onChange={formik.handleChange} />
                </FormGroup>
                {formik.touched.usrFullName && Boolean(formik.errors.usrFullName) && <label className="mt-0 mb-3 text-danger">{formik.errors.usrFullName}</label>}
              </Col>
            </Row>
            {action !== 'READ' &&
              <>
                <Row>
                  <Label sm="2">Password</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        type="password"
                        autoComplete="off"
                        name="usrPswd"
                        value={formik.values.usrPswd}
                        onChange={formik.handleChange} />
                    </FormGroup>
                    {formik.touched.usrPswd && Boolean(formik.errors.usrPswd) && <label className="mt-0 mb-3 text-danger">{formik.errors.usrPswd}</label>}
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Password Confirmation</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        type="password"
                        autoComplete="off"
                        name="passwordConfirmation"
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange} />
                    </FormGroup>
                    {formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation) && <label className="mt-0 mb-3 text-danger">{formik.errors.passwordConfirmation}</label>}
                  </Col>
                </Row>
              </>
            }
            <Row>
              <Label sm="2">Role</Label>
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Choose role"
                    isDisabled={ action === 'READ' }
                    value={formik.values.usrRole}
                    onChange={(value) => formik.setFieldValue('usrRole', value)}
                    options={dataRole}
                  />
                </FormGroup>
                {formik.touched.usrRole && Boolean(formik.errors.usrRole) && <label className="mt-0 mb-3 text-danger">{formik.errors.usrRole}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2">Locations</Label>
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Choose locations"
                    name="multipleSelect"
                    isMulti
                    isDisabled={ action === 'READ' }
                    value={formik.values.locationIDS}
                    onChange={(value) => formik.setFieldValue('locationIDS', value)}
                    options={dataLocation}
                  />
                </FormGroup>
                {formik.touched.locations && Boolean(formik.errors.locations) && <label className="mt-0 mb-3 text-danger">{formik.errors.locations}</label>}
              </Col>
            </Row>
            <Row>
              <Label sm="2" />
              <Col sm="10" className="text-right">
                <FormGroup>
                  <Button
                      type="submit"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      {action === 'READ' ? 'Close' : 'Cancel'}
                  </Button>
                  {action !== 'READ' && 
                    <Button
                      type="submit"
                      color="primary">
                      Save
                    </Button>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default UserForm;
