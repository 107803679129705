import React, { useEffect, useState } from "react";
import { FormGroup, Form, Row, Col, Label } from "reactstrap";
import Select from "react-select";
import axios from "../../utils/axios";

function JobSelectLocationModalForm(props) {
  const { formik, setLabel } = props;
  const [project, setProject] = useState();
  const [building, setBuilding] = useState();
  const [floor, setFloor] = useState();
  const [room, setRoom] = useState();

  const [projects, setProjects] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("/api/locations/project");
        const { data } = await response.data;
        const projectsData = [];
        data.map((project) => {
          projectsData.push({
            value: project.locID,
            label: project.locName,
            code: project.locCode,
          });
        });
        setProjects(projectsData);
      } catch (error) {
        console.log(error);
        alert("Cannot fetch data projects");
      }
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    if (project) {
      const fetchBuildings = async () => {
        const response = await axios.get(
          "/api/locations/building/" + project.code
        );
        const { data } = await response.data;
        const buildingsData = [];
        console.log(data);
        data.map((building) => {
          buildingsData.push({
            value: building.locID,
            label: building.locName,
            code: building.locCode,
          });
        });
        setBuildings(buildingsData);
      };
      fetchBuildings();
    }
  }, [project]);

  useEffect(() => {
    if (building) {
      const fetchFloors = async () => {
        const response = await axios.get(
          "/api/locations/floor/" + building.code
        );
        const { data } = await response.data;
        const floorsData = [];
        console.log(data);
        data.map((floor) => {
          floorsData.push({
            value: floor.locID,
            label: floor.locName,
            code: floor.locCode,
          });
        });
        setFloors(floorsData);
      };
      fetchFloors();
    }
  }, [building]);

  useEffect(() => {
    if (floor) {
      const fetchRooms = async () => {
        const response = await axios.get("/api/locations/room/" + floor.code);
        const { data } = await response.data;
        const roomsData = [];
        console.log(data);
        data.map((room) => {
          roomsData.push({
            value: room.locID,
            label: room.locName,
            code: room.locCode,
          });
        });
        setRooms(roomsData);
      };
      fetchRooms();
    }
  }, [floor]);

  const handleChangeProject = (value) => {
    formik.setFieldValue("joblocID", value.value);
    setLabel(value.label);
    setProject(value);
    setBuilding("");
    setFloor("");
  };
  const handleChangeBuilding = (value) => {
    formik.setFieldValue("joblocID", value.value);
    setLabel(`${project.label} / ${value.label}`);
    setBuilding(value);
    setFloor("");
  };
  const handleChangeFloor = (value) => {
    formik.setFieldValue("joblocID", value.value);
    setLabel(`${project.label} / ${building.label} / ${value.label}`);
    setFloor(value);
    setRoom("");
  };
  const handleChangeRoom = (value) => {
    formik.setFieldValue("joblocID", value.value);
    setLabel(
      `${project.label} / ${building.label} / ${floor.label} / ${value.label}`
    );
    setRoom(value);
  };

  return (
    <>
      <Row>
        <Label sm="3">Project</Label>
        <Col sm="9">
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose project"
              value={project}
              onChange={(value) => handleChangeProject(value)}
              options={projects}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label sm="3">Building</Label>
        <Col sm="9">
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose building"
              isDisabled={!project}
              value={building}
              onChange={(value) => handleChangeBuilding(value)}
              options={buildings}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label sm="3">Floor</Label>
        <Col sm="9">
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose floor"
              isDisabled={!building}
              value={floor}
              onChange={(value) => handleChangeFloor(value)}
              options={floors}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label sm="3">Room</Label>
        <Col sm="9">
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose room"
              isDisabled={!floor}
              value={room}
              onChange={(value) => handleChangeRoom(value)}
              options={rooms}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default JobSelectLocationModalForm;
