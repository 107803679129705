import React from "react";
import { Badge, Button, Card, CardBody, Input } from "reactstrap";
import JobAddComment from "./JobAddComment";

const JobComments = (props) => {
  const { comments } = props;
  return (
    <Card
      className="card-timeline"
      style={{
        backgroundColor: "transparent",
        boxShadow: "unset",
        overflowY: "scroll",
        maxHeight: "82.5vh",
      }}
    >
      <CardBody>
        <ul className="timeline timeline-simple">
          {comments.map((comment) => (
            <li key={comment.jcoID} className="timeline-inverted">
              <div
                className="timeline-badge"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: comment.user.usrColorCode
                }}
              >
                {comment.user.usrInitial}
              </div>
              <div className="timeline-panel">
                <div className="timeline-body">
                  <p style={{ minHeight: comment.jcoImagePath ? "57px" : "" }}>
                    {comment.jcoImagePath && (
                      <a href={comment.jcoImagePath} target="_blank">
                        <img
                          src={comment.jcoImagePath}
                          style={{
                            float: "left",
                            marginRight: "7px",
                            objectFit: "cover",
                            width: "55px",
                            height: "55px",
                          }}
                        />
                      </a>
                    )}
                    {comment.jcoDescription}
                  </p>
                  <hr style={{ margin: "5px 0px"}} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontSize: "9px", color: "#929292" }}>
                      {/* <Badge style={{ backgroundColor: comment.user.usrColorCode, fontSize: "9px" }}>{comment.user.usrFullName}</Badge> */}
                      <span style={{ border: "1px solid #cacaca", padding: "0px 2px", marginRight: "5px" }}>Crew</span>
                      {comment.user.usrFullName}
                    </div>
                    <div style={{ color: "#929292", fontSize: "9px" }}>{comment.jcoDatetime}</div>
                  </div>
                </div>
              </div>
            </li>
          ))}

          <JobAddComment />
        </ul>
      </CardBody>
    </Card>
  );
};

export default JobComments;
